.top-tabs {
    position: relative;
    top: 1px;
    user-select: none;
}

.top-tabs.ant-tabs-top > .ant-tabs-nav::before, 
.top-tabs.ant-tabs-bottom > .ant-tabs-nav::before, 
.top-tabs.ant-tabs-top > div > .ant-tabs-nav::before, 
.top-tabs.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0;
}

.top-tabs .ant-tabs-nav {
    margin: 0!important;
}

.top-tabs .ant-tabs-tab-btn {
    font-weight: 600;
}

.top-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, 
.top-tabs.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px 9px;
    background: transparent;
    border: 1px solid transparent;
    border-bottom: 0;
}


.top-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0097EB;
}

.top-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, 
.top-tabs.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #0097EB;
    background: #f9faff;
    border-color: #e6e8f1;
}
