.tabsForm {
    margin: -24px;
}

.tabsForm > .ant-tabs-nav {
    padding: 8px 24px 0;
    background: #fff;
    border-bottom-color: #EFF0F4;
}

.tabsForm > .ant-tabs-content-holder {
    padding: 0 24px;
}

.tabsForm.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background: #FBFCFD;
    border-bottom-color: #FBFCFD;
}