.table-wrap {
    position: relative;
}

.ant-table {
    background: transparent;
}

.ant-table-body table {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.ant-table-header, .ant-table-thead > tr > th {
    background: transparent;
    color: #56636f;
    font-size: 11px;
    text-transform: uppercase;
}

.ant-table-thead > tr > th {
    border-bottom: 0;
}

.ant-table-tbody > tr > td {
    margin-bottom: 15px;
    color: #404346;
    background: #fff!important;
    border-top: 1px solid #E7EBF6;
    border-bottom: 1px solid #E7EBF6;
}
.ant-table-tbody > tr:hover > td {
    background: #E8F4F9 !important;
}
.ant-table-tbody > tr > td:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #E7EBF6;
}
.ant-table-tbody > tr > td:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid #E7EBF6;
}

.ant-table-tbody > tr.ant-table-row:nth-child(2n+1) > td,
.ant-table-tbody > tr.ant-table-row:nth-child(2n+1):hover > td {
    background: #fff;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {

}

.ant-table-scroll {
    overflow: auto;
    overflow-x: hidden;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: transparent;
}


.ant-table-wrapper, .ant-spin-nested-loading, .ant-spin-container {
    height: 100%;
}

.ant-table {
    height: calc(100% - 46px);
}


.table-filters {
    display: flex;
    padding: 16px 0;
    color: #7a8895;
    font-size: 12px;
    line-height: 1;
}

.table-filters-item {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.table-filters-item-label {
    margin-right: 5px;
}

.table-filters-item-values {
    display: flex;
}

.table-stat {
    position: absolute;
    bottom: 15px;
    left: 0;
    height: 33px;
    line-height: 32px;
    padding: 0 16px;
    color: #596570;
    font-size: 12px;
}

.ant-table-placeholder {
    border-radius: 0;
    border-bottom: 1px solid transparent;
}

.ant-table-thead > tr > th .ant-table-column-sorter {
    vertical-align: top;
}

.ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-icon {
    color: #596570;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: transparent;
}
.ant-table-thead > tr > th.ant-table-column-sort {

    background: transparent;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
    background: transparent;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
    color: #616A75;
    background: transparent;
}

.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: #40a9ff!important;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    color: #40a9ff;
    background-color: transparent;
}


.ant-table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
}

.ant-table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}


.table-row-actions {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.table-row-action {
    margin-right: 12px;
}