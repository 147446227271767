.pageType {
    display: inline-block;
    font-size: 12px;
    line-height: 1.125;
    padding: 4px 8px;
    border-radius: 2em;
    color: rgba(0,0,0,.88);
    background: rgba(0,0,0,.02);
    border: 1px solid #d9d9d9;
    overflow: hidden;
}
