.top {
    position: relative;
    z-index: 2;
    background: #fff;
    border-bottom: 1px solid #e6e8f1;
}

.top-main {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    padding: 0 24px;
    height: 50px;
}

.top-main-start {
    
}

.top-main-title {
    margin-right: 16px;
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
}

.top-main-middle {
    flex: 1;
}

.top-main-end {
    display: flex;
    align-items: center;
    height: 50px;
}
