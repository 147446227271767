.table-top-main {
    display: flex;
}

.table-top-main-start {
    display: flex;
}

.table-top-main-end {
    margin-left: 16px;
}


.table-top-search {
    margin-right: 16px;
}