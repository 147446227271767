.ant-menu-submenu {
    margin-bottom: 25px;
}

.ant-menu-item-group {
    margin-bottom: 25px;
}

.ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    color: #414952 !important;
}

.ant-menu-dark .ant-menu-item {
    border-radius: 0 !important;
    border: 2px solid transparent !important;
}

.ant-menu-item {
    margin-bottom: 0 !important;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    color: #616A75;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a {
    color: #616A75;
}

.ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-left-color: #40a9ff !important;
}

.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-submenu-selected .anticon {
    color: #40a9ff;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
}

.ant-menu-sub .ant-menu-item:before {
    content: '';
    position: absolute;
    left: 27px;
    top: 50%;
    transform: translateY(-2px);
    width: 5px;
    height: 5px;
    border: 1px solid #616A75;
    border-radius: 50%;
    transition: all .3s ease-out;
}

.ant-menu-sub .ant-menu-item-selected:before,
.ant-menu-sub .ant-menu-item:hover:before {
    background: #40a9ff;
    border-color: #40a9ff;
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected {
    background: #252E35;
    border-radius: 6px;
}

.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-title {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.13);
}

.ant-menu-submenu-open {
    background: #252E35;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: transparent;
    box-shadow: none;
}

.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}