.selectCardItem {
    display: flex;
}
.selectCardItemImage {
    margin-right: 10px;
}
.selectCardItemName {
    margin-bottom: 8px;
}
.selectCardItemProp {
    margin-bottom: 5px;
    font-size: .85em;
    line-height: 1.125;
}
.ant-select-selection-item .selectCardItem {
    
}
.ant-select-selection-item .selectCardItemImage {
    display: none;
}
.ant-select-selection-item .selectCardItemProp {
    display: none;
}
