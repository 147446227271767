
.ant-form-item-label {
    text-align: left;
    font-weight: 500;
    white-space: normal;
}

.ant-form-item-label > label {
    display: inline-block;
    color: #627582;
    line-height: 1.5;
}

.ant-form label {
    font-size: 13px;
}


.ant-upload-disabled {
    opacity: .7;
}

.ant-upload-disabled .ant-btn:hover {
    color: #000;
    background-color: #fff;
    border-color: #d9d9d9;
}


.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    display: block;
    height: 8px !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    top: 42%;
    left: 22%;
    display: block;
    width: 6px;
    height: 10px;
}