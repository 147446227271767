/* .page-header-nav */

.page-header-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 24px;
}


.page-header-nav .back-btn {
    display: flex;
    align-items: center;
    margin-top: 12px;
    font-size: 13px;
    line-height: 1;
}

.page-header-nav .back-btn .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b7892;
}

.page-header-nav .back-btn .text {
    margin-left: 8px;
    color: #6b7892;
}

.page-header-nav .ant-breadcrumb {
    font-size: 13px;
    line-height: 1;
}

.ant-breadcrumb a,
.ant-breadcrumb>span:last-child a {
    color: #6b7892;
}

.ant-breadcrumb a:hover {
    color: #5bb5f5 !important;
}