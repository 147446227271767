.tree {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.ant-layout-sider-collapsed .tree-title,
.ant-layout-sider-collapsed .tree .ant-tree {
  display: none;
}

.ant-layout-sider-collapsed .tree-trigger {
  color: #fff;
  background: #0079bb;
}
.ant-layout-sider-collapsed .tree-trigger:after {
  border-color: #fff;
  transform: rotate(45deg) translate(-1px, 1px);
}

.tree-trigger {
  position: absolute;
  z-index: 10;
  right: -10px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #e6e8f1;
  background: #fff;
  cursor: pointer;
}
.tree-trigger:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 1px solid #404346;
  border-right: 1px solid #404346;
  transform: rotate(225deg) translate(-1px, 1px);
  transform-origin: 50% 50%;
}
.tree-trigger > .anticon {
  font-size: 14px;
}

.tree-title {
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  font-weight: 700;
}
.tree-title > .anticon {
  width: 32px;
}

.tree .ant-tree {
  flex: 1;
  padding: 0 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tree .ant-tree::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.tree .ant-tree::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
}

.tree .ant-tree::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  outline: none;
  border-radius: 1em;
}

.tree .ant-tree-treenode {
  width: 100%;
}

.tree .ant-tree-treenode:nth-child(1) .ant-tree-node-content-wrapper,
.tree .ant-tree-treenode:nth-child(2) .ant-tree-node-content-wrapper {
  background-color: #e5f3fc;
}

.tree .ant-tree-switcher {
  padding-top: 8px;
}

.tree .ant-tree-node-content-wrapper {
  width: 100%;
  height: 40px !important;
  line-height: 40px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree .ant-tree .ant-tree-node-content-wrapper {
  padding: 0 8px;
  border-radius: 4px;
}

.tree .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #e5f3fc;
}
.tree .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #0097eb;
  color: #fff;
}

.tree-node-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.tree-node-main-title {
  flex: 1;

  display: block;
  display: -webkit-box;

  margin: 0 auto;
  font-size: 14px;
  line-height: 1.25;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.tree-node-main-count {
  display: block;
  margin-left: 4px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
}
