.descriptions {
}

.descriptionsItem {
}

.descriptionsItemWrap {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #EAEBF0;
    background: #fff;
}

.descriptionsItemLabel {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 1;
    color: #7c899b;
}

.descriptionsItemContent {

}

.descriptionsItemContent--empty:after {
    content: '-';
}

.descriptionsItem--sm .descriptionsItemContent {
    font-size: 13px;
}