.prop {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 1.125;
}

.propLabel {
    margin-right: 5px;
    color: #7c899b;
}

.propValue {
    
}