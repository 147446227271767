.image {
    padding: 2px;
    border: 1px solid #efefef;
}

.imageInner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    overflow: hidden;
}

.imageInner img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.imageEmpty {
    text-align: center;
    color: #666;
    font-size: 12px;
}


.imageEmpty .anticon {
    margin-bottom: 10px;
    font-size: 24px;
}