@import '~antd/dist/antd.css';

@import "layout/index.css";

@import "nav/index.css";
@import "table_top.css";
@import "table.css";
@import "calendar.css";
@import "modal.css";
@import "form.css";
@import "form/select.css";
@import "field_value.css";
@import "carousel.css";
@import "tabs_form.css";

*,
*::before,
*::after {
  box-sizing: border-box;
  /*user-select: none;*/
}

.disable-hover,
.disable-hover * {
  pointer-events: none !important;
}

html {}

body {
  /*font-family: Helvetica, Arial, sans-serif;*/
  font-family: 'Nunito', sans-serif;
  font-size: 16px;

  font-weight: 400;
  line-height: 1.375;
  /*color: #414954;
    fill: #414954;*/
  color: #404346;
  margin: 0;
  padding: 0;
  background: #f9faff;
  /*user-select: none;*/

  /*text-rendering: optimizeLegibility;*/
}

a.color-main {
  color: #404346;
}

.ant-btn-primary1 {
  box-shadow: 0 10px 15px rgba(0, 0, 0, .15);
}

.ant-btn {
  /*border-radius: 2em;*/
  font-size: 13px;
  font-weight: 600;
}

.ant-calendar,
.ant-time-picker-panel-inner,
.ant-select-dropdown {
  background: #fff;
}

.dateControlPicker .ant-input-lg {
  height: 46px;
  padding: 6px 11px;
  font-size: 24px;
}

.dateControlPicker .anticon-calendar {
  font-size: 20px;
  margin-top: -10px;
  right: 18px;
}

.work-mode0 {
  background-color: #65c16f;
}

.work-mode1 {
  background-color: #F2A600;
}


.drag {
  cursor: move;
}

.actionLink {
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #02c385;
  cursor: pointer;
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

.actionLink .anticon {
  margin-right: 5px;
  font-size: 16px;
}

.actionLink.red {
  color: #ee513b;
}


.gallery-slide {
  position: relative;
  text-align: center;
  height: 300px;
  line-height: 300px;
  background: #364d79;
  overflow: hidden;
}

.gallery-slide-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.ant-carousel .slick-dots li button {
  background: #fff;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
}


.action-button {
  font-size: 22px;
}


.ant-tabs-bar {
  border-bottom: none;
  margin-bottom: 1px;
}


.ant-tag {
  padding: 0 5px;
  color: #fff;
  border: 1px solid #0097eb;
  background: #0097eb;
  display: flex;
  align-items: center;
}

.ant-tag span {
  flex: 1;
}

.ant-tag .anticon-close {
  position: relative;
  left: 2px;
  color: #fff;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #5F6B78;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #5F6B78;
}

.spin-inline {
  display: inline-block;
}

.ant-collapse {
  color: #fff;
  background-color: #2C343A;
  border: 1px solid #5F6B78;
  border-bottom: 0;
  border-radius: 12px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: #fff;
}

.ant-collapse-content {
  border-top: 1px solid #5F6B78;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #5F6B78;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0 0 12px 12px;
}

.ant-collapse-item:last-child>.ant-collapse-content {
  border-radius: 0 0 12px 12px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


.ck-editor__editable {
  background: #fff;
  border: 1px solid #d9d9d9!important;
}