.container {
    position: relative;
    padding: 24px;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 32px;
    border: 1px solid #f0f2fa;
}

.containerWithHeader {
    padding-top: 24px;
}

.container:last-child {
    margin-bottom: 0;
}


.header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.headerMain {
    flex: 1;
}

.headerActions {

}

.headerTitle {
    font-size: 18px;
    line-height: 1;
    color: #7c899b;
}

.content {

}