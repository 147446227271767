.site-chooser {
    width: 200px;
    height: 36px;
    padding: 0 4px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    border-radius: 2em;
    /*background: linear-gradient(to right, #3196e8 0%, #30c8ca 100%);
    /*box-shadow: 0 3px 9px rgba(0, 0, 0, .2);*/
    border: 1px dashed #3196e8;
    transition: all .3s ease-out;
    background: #fff;
}

.site-chooser-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 16px;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    background: linear-gradient(to right, #3196e8 0%, #30c8ca 100%);
}

.site-chooser-main {
    flex: 1;
    overflow: hidden;
}

.site-chooser-icon {
    margin-right: -5px;
    margin-left: 8px;
    color: #414954;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
}

.site-chooser-title {
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
}

.site-chooser-note {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: #414954;
}

.menuInner {
    max-width: 320px;
    padding: 15px!important;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    margin-bottom: 10px!important;
    padding: 0 4px!important;
    cursor: pointer;

    border-radius: 2em;
    border: 1px dashed #d1d5e0;
    transition: all .3s ease-out;
}

.menuItem:last-child {
    margin-bottom: 0!important;
}

.menuItem:hover {
    background: rgba(49, 150, 232, 0.1);
}

.menuItemActive {
    border-color: #3196e8;
}

.menuLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #d1d5e0;
    color: #fff;
}

.menuItemActive .menuLogo {
    background: #3196e8;
}

.menuContent {
    overflow: hidden;
    flex: 1;
}

.menuTitle {
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
}

.menuItemActive .menuTitle {
    color: #3196e8;
}

.menuAddress {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: #414954;
}