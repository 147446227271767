.page {
    /*display: flex;
    flex-flow: column;*
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;*/
    height: 100%;
    overflow-y: scroll;
}

.page::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
 
.page::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    border-radius: 1em;
}
 
.page::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  outline: none;
  border-radius: 1em;
}

.page-aside.ant-layout-sider {
    background: #fff;

    width: 250px;
    height: 100%;
    border-right: 1px solid #e6e8f1;
}

.page-aside.ant-layout-sider-zero-width > * {
    overflow: visible;
}

.page-content-inner {
    /*padding-right: 30px;*/
}

.page-main {
    flex: 1;
    /*overflow-y: auto;*/
    padding: 1px 0 24px 24px;
}

.pageMainWithTabs {
    flex: 1;
    padding: 24px;
    background: #f3f4f9;
    border: 1px solid #dfe1eb;
}
