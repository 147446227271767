.page-header {
    position: relative;
    z-index: 2;
    top: 1px;
    background: #f9faff;
    /*border-bottom: 1px solid #e6e8f1;*/
}

.page-header-main {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 40px 24px 24px;
}

.page-header-main-start {
    flex: 1;
    min-width: 0; 
}

.page-header-main-end {
    display: flex;
    align-items: center;
}

.page-header-title {
    font-size: 22px;
    line-height: 1.125;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1a3643;
}

.page-header-info {
    margin-top: 8px;
    font-size: 14px;
    color: #6b7892;
}

.header-actions {
    display: flex;
    align-items: center;
}

.header-actions-item {
    margin-left: 16px;
}