.ant-carousel {
    overflow: hidden;
}

.ant-carousel .slick-dots-bottom {
    bottom: -5px;
}

.ant-carousel .slick-dots li button {
    background: #3196e8!important;
}
.ant-carousel .slick-dots li.slick-active button {
    background: #3196e8!important;
}
