.navigation.ant-menu {
  flex: 1;
  width: 220px;
  margin: 0 10px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #0097eb;
  border: 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.75);
}

.navigation.ant-menu-inline-collapsed {
  width: 100px;
  padding: 0;
  margin: 0;
}

.navigation.ant-menu .anticon {
  font-size: 18px;
}

.navigation.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.navigation.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon {
  font-size: 24px;
  line-height: 1;
}

.navigation .ant-menu-item-group {
  margin-top: 15px;
  margin-bottom: 0;
}

.navigation.ant-menu-inline-collapsed > .ant-menu-item-group {
  border-top: 1px solid #0079bb;
  margin-top: 16px;
  padding-top: 4px;
}

.navigation .ant-menu-item-group-title {
  padding: 0 10px;
  text-transform: none;
  font-size: 13px;
  font-weight: 400;
  color: #005e91 !important;
}

.navigation.ant-menu-inline-collapsed .ant-menu-item-group-title {
  display: none;
}

.navigation.ant-menu .ant-menu-item {
  display: flex;
  align-items: center;
  width: 70px;
  margin: 0 25px 0 5px;
  padding: 0 10px;
  height: 28px;
  line-height: 1;
  border-radius: 8px;
}

.navigation.ant-menu-inline-collapsed .ant-menu-item,
.navigation.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
  padding: 0 5px;
  margin-top: 16px;
  height: 50px;
  flex-flow: column;
  justify-content: center;
  text-align: center;
}

.navigation.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.navigation.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon
  + span {
  opacity: 1;
  display: block;
  width: 100%;
  max-width: 100%;
  font-size: 9px;
}

.navigation.ant-menu .ant-menu-item-selected::after {
  display: none;
}

.navigation.ant-menu .ant-menu-item:hover {
  color: #fff;
}

.navigation.ant-menu .ant-menu-item:active {
  background-color: #0079bb;
}

.navigation.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #fff;
  background-color: #0079bb;
}
