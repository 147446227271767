.app {
    position: relative;
    z-index: 2;
    min-height: 100vh;
    background: transparent;
}

.app-sider {
    height: 100vh;
    z-index: 3;
    left: 0;
    padding: 0;
    background: #0097EB;
}

.app-sider .ant-layout-sider-children {
    display: flex;
    flex-flow: column;
    height: 100vh;
    overflow: hidden;
}

.app-main {
    height: 100vh;
    background: #f9faff;
}

.ant-layout {
    background: #f9faff;
}

.layout-inner {
    flex: 1;
    /*overflow: hidden;*/
}