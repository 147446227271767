.breadcrumbs {
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    color: #868F9E;
    fill: #868F9E;
    overflow: hidden;
}

.breadcrumbsInner {
    padding: 0 0 20px 0;
    overflow-x: auto;
}

.breadcrumbsList {
    white-space: nowrap;
}

.breadcrumbsItem {
    display: inline-block;
}

.breadcrumbsItem a {
    color: #868F9E;
}

.breadcrumbsItem:after {
    display: inline-block;
    vertical-align: middle;
    padding: 0 4px 0 6px;
    font-size: 14px;
    content: "/\A0";
}

.breadcrumbsItem:last-child:after {
    display: none;
}
