.logo {
    margin: 10px 0 30px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-inner {
    position: relative;
    width: 128px;
    height: 64px;
    background: url(../../../assets/img/logo-w.png) 0 0 no-repeat;
    background-size: contain;
}

.app-sider.ant-layout-sider-collapsed .logo {
    width: 100%;
}

.app-sider.ant-layout-sider-collapsed .logo-inner {
    width: 80px;
    height: 94px;
    background: url(../../../assets/img/logo-wm.png) 0 0 no-repeat;
    background-size: contain;
}