.user-panel {
    display: flex;
    align-items: center;
    height: 60px;
    cursor: pointer;
    padding: 0 12px;

    transition: all 0.3s;
    font-size: 0;

    margin-top: 16px;
    border-top: 1px solid #0079bb;
    background: #0097EB;
    color: #fff;
}

.user-panel-avatar {
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px !important;
    color: #0097EB !important;
    background: #fff !important;
    vertical-align: top;
    text-transform: uppercase;
    font-weight: 600;
}

.user-panel-name {
    flex: 1;
    min-width: 0;
    font-size: 12px;
}

.user-panel-name span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-pnael-dropdown {}

.ant-layout-sider-collapsed .user-panel {
    flex-flow: column;
    justify-content: center;
}

.ant-layout-sider-collapsed .user-panel-avatar {
    margin-right: 0 !important;
}

.ant-layout-sider-collapsed .user-panel-name {
    flex: none;
    width: 70px;
    margin-top: 4px;
    font-size: 10px;
    text-align: center;
}