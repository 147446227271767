.pageHeaderTabs {
    
}

.pageHeaderTabs > .ant-tabs-nav {
    margin: 0;
    padding: 0 24px;
    background: #f9faff;
    user-select: none;
}

.pageHeaderTabs .ant-tabs-ink-bar {
    display: none;
}

.pageHeaderTabs > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.pageHeaderTabs.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, 
.pageHeaderTabs.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, 
.pageHeaderTabs.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, 
.pageHeaderTabs.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 2px;
}

.pageHeaderTabs > .ant-tabs-nav .ant-tabs-tab-active, 
.pageHeaderTabs > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #0097EB;
    background: #f3f4f9;
    border-color: #dfe1eb;
    border-bottom-color: #f3f4f9;
}


.tabs .ant-tabs-top-bar {
    margin-bottom: 10px !important;
}


.ant-tabs-bottom .ant-tabs-bottom-bar {
    position: relative;
    top: -3px;
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid transparent;
    border-bottom: none;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 24px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 24px;
    margin: 0;
    margin-right: 2px;
    padding: 0 6px;
    line-height: 24px;
    color: #9aafc1;
    background: #343C43;
    border: 1px solid #596570;
    border-radius: 0 0 4px 4px;
    font-size: 10px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 24px;
    color: #fff;
    background: #21282E;
    border-color: #3F484E;
    border-top: 1px solid #21282E;
}

.ant-tabs-bottom-content {
    position: relative;
    z-index: 2;
}

.ant-tabs-bottom .ant-form-item {
    margin-bottom: 0;
}
